'use strict';

document.addEventListener('DOMContentLoaded', function () {
  /* ------------------------
  変数まとめ
  ------------------------ */
  const body = document.body;
  const header = document.querySelector('header');
  const hamburgerMenu = document.querySelector('.js_hamburgerMenu');
  // const footer = document.querySelector('footer');
  // const bg = document.querySelector(".js_bg");
  // const menu = document.querySelector(".js_menu");
  // const mv = document.querySelector('.un_front_mv');
  // const blNumber = document.querySelector('.bl_number');

  /* ------------------------
  ハンバーガーメニューの開閉
  ------------------------ */
  // Toggle class for hamburger menu
  function toggleMenu() {
    body.classList.toggle('is_hidden');
    header.classList.toggle('is_open');
    hamburgerMenu.classList.toggle('is_open');
  }
  // Event listeners
  hamburgerMenu.addEventListener('click', toggleMenu);

  /* ------------------------
  selectタグ 時間指定
  ------------------------ */
  const selectElements = document.querySelectorAll('.timeSelect');
  selectElements.forEach((selectElement) => {
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const value = `${String(hour).padStart(2, '0')}:${String(
          minute
        ).padStart(2, '0')}`;
        const option = new Option(value, value);
        selectElement.appendChild(option);
      }
    }
  });
});

/* ------------------------
タブ切り替え
------------------------ */
function showContent(index) {
  const tabs = document.querySelectorAll('.tab');
  const contents = document.querySelectorAll('.tabContent');

  for (let i = 0; i < tabs.length; i++) {
    tabs[i].classList.remove('active');
    contents[i].style.display = 'none';
    contents[i].classList.add('fade');
  }

  tabs[index].classList.add('active');
  contents[index].style.display = 'block';
  setTimeout(function () {
    contents[index].classList.remove('fade');
  }, 10);
}
